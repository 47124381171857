import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { SpinnerGif } from './SpinnerGif';

interface IChangeAnswerSpinnerProps {
    width?: string;
    height?: string;
    spinnerSize?: string;
    visible?: boolean;
    fullScreen?: boolean;
    isSideMenu?: boolean;
    message?: string;
}

const ChangeAnswerSpinner: React.FC<IChangeAnswerSpinnerProps> = ({
    width,
    height,
    spinnerSize = '40px',
    message = 'Loading',
}: IChangeAnswerSpinnerProps) => {
    return (
        <Container id="change-answer-spinner">
            <InfoText>{message}</InfoText>
            <ImageWrapper width={width} height={height}>
                <SpinnerGif size={spinnerSize} />
            </ImageWrapper>
        </Container>
    );
};

export default ChangeAnswerSpinner;

const fadeIn = keyframes`
    0% {
        opacity: .5;
    }
    100% {
        opacity: .80;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: .80;
    }
    60% {
        opacity: .5;
    }
    100% {
        opacity: 0;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    display: inline-block;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.75);

    left: 0;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    visibility: ${(visible) => (!visible ? 'hidden' : 'visible')};
    animation: ${(visible) => (!visible ? fadeOut : fadeIn)} 2.5s linear;
    transition: visibility 2.5s linear;

    @media only screen and (max-width: 1025px) {
        width: 100vw;
        height: 100vh;
    }

    /* Landscape */
    @media only screen and (min-device-width: 200px) and (max-device-width: 900px) and (max-device-height: 1000px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
        top: 0px;
    }
`;

const ImageWrapper = styled.div<{
    width?: string;
    height?: string;
}>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '250px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.isCached {
        img {
            top: -35px;
        }
    }
    img {
        opacity: 0.75;
    }
`;

const InfoText = styled.div`
    font-family: Montserrat;
    color: #9c9c9c;
    line-height: 32px;
    font-size: 24px;
    text-align: center;
    font-weight: 400;

    @media only screen and (max-width: 1280px) {
        font-size: 18px;
    }
`;
