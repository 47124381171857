import { action, observable, makeAutoObservable } from 'mobx';
import { StockDetails } from 'models/StockDetails';

export class FinancesStore {
    // Observable properties for financial data
    @observable
    personalInvestment = 10000; // Example initial value

    @observable
    selectedStock: StockDetails = {
        companyName: '',
        currentPrice: 0,
        high: 0,
        low: 0,
        open: 0,
        close: 0,
        changePercent: 0,
        marketCap: 0,
        peRatio: 0,
        dividendYield: 0,
        news: [],
        analystRatings: {
            buy: 0,
            hold: 0,
            sell: 0,
        },
    };

    @observable
    currentProfitLoss = 500; // Example initial value

    @observable
    riskLevel = 'Moderate'; // Example initial value

    @observable
    stockData = {
        currentPrice: 0,
        high: 0,
        low: 0,
        open: 0,
        close: 0,
        changePercent: 0,
        marketCap: 0,
        peRatio: 0,
        dividendYield: 0,
        news: [],
        analystRatings: {
            buy: 0,
            hold: 0,
            sell: 0,
        },
    };

    constructor() {
        makeAutoObservable(this);
    }

    // Action to update personal investment
    @action
    setPersonalInvestment(value: number) {
        this.personalInvestment = value;
    }

    // Action to update current profit/loss
    @action
    setCurrentProfitLoss(value: number) {
        this.currentProfitLoss = value;
    }

    // Action to update risk level
    @action
    setRiskLevel(value: string) {
        this.riskLevel = value;
    }

    // Action to update stock data
    @action
    setStockData(data: typeof this.stockData) {
        this.stockData = data;
    }

    // Action to reset financial data
    @action
    resetFinancials() {
        this.personalInvestment = 10000;
        this.currentProfitLoss = 500;
        this.riskLevel = 'Moderate';
        this.stockData = {
            currentPrice: 0,
            high: 0,
            low: 0,
            open: 0,
            close: 0,
            changePercent: 0,
            marketCap: 0,
            peRatio: 0,
            dividendYield: 0,
            news: [],
            analystRatings: {
                buy: 0,
                hold: 0,
                sell: 0,
            },
        };
    }

    @action
    setSelectedStock(symbol: StockDetails) {
        this.selectedStock = symbol;
    }
}
