import { useEffect, useState, useCallback } from 'react';
import { android, iOS, isFirefox, isIphone, isMac, isUserUsingMobile, debounce } from '../utils/browserUtils';

export enum DEVICE_TYPE {
    IOS = 'iOS',
    IPHONE = 'iPhone',
    ANDROID = 'Android',
    MAC = 'Mac',
    FIREFOX = 'Firefox',
    OTHER_MOBILE = 'Other Mobile',
    DESKTOP = 'Desktop',
    TABLET = 'Tablet',
    WINDOWS = 'Windows',
    LINUX = 'Linux',
}

export enum ORIENTATION {
    LANDSCAPE = 'Landscape',
    PORTRAIT = 'Portrait',
}

export const MEDIUM_DEVICE_WIDTH = 1025;
export const SMALL_DEVICE_WIDTH = 720;
const MIN_DEVICE_WIDTH = '200px';
const MAX_DEVICE_WIDTH = '900px';
const MAX_DEVICE_HEIGHT = '1000px';
const ASPECT_RATIO = '13/9';

export const LANDSCAPE_DEVICE_CONDITION = `
    (min-device-width: ${MIN_DEVICE_WIDTH}) and 
    (max-device-width: ${MAX_DEVICE_WIDTH}) and 
    (max-device-height: ${MAX_DEVICE_HEIGHT}) and 
    (orientation: landscape) and 
    (min-aspect-ratio: ${ASPECT_RATIO})
`;

export { android, iOS, isFirefox, isIphone, isMac, isUserUsingMobile };

export const useDeviceInfo = (): {
    isMobile: boolean;
    deviceType: DEVICE_TYPE;
    deviceOrientation: ORIENTATION;
    deviceDimensions: { width: number; height: number };
    isTouchDevice: boolean;
    isOnline: boolean;
} => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [deviceType, setDeviceType] = useState<DEVICE_TYPE>(DEVICE_TYPE.DESKTOP);
    const [deviceOrientation, setOrientation] = useState<ORIENTATION>(ORIENTATION.PORTRAIT);
    const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);
    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

    const deviceDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleResize = useCallback(() => {
        setIsMobile(window.innerWidth < MEDIUM_DEVICE_WIDTH);
    }, []);

    const detectDeviceType = useCallback(() => {
        if (iOS()) return DEVICE_TYPE.IOS;
        if (isIphone()) return DEVICE_TYPE.IPHONE;
        if (android()) return DEVICE_TYPE.ANDROID;
        if (isMac()) return DEVICE_TYPE.MAC;
        if (isFirefox()) return DEVICE_TYPE.FIREFOX;
        if (navigator.userAgent.includes('Windows')) return DEVICE_TYPE.WINDOWS;
        if (navigator.userAgent.includes('Linux')) return DEVICE_TYPE.LINUX;
        if (isUserUsingMobile()) return DEVICE_TYPE.OTHER_MOBILE;
        return DEVICE_TYPE.DESKTOP;
    }, []);

    const handleOrientationChange = useCallback(() => {
        const landscape = window.matchMedia('(orientation: landscape)').matches;
        setOrientation(landscape ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT);
    }, []);

    const handleOnlineStatusChange = useCallback(() => {
        setIsOnline(navigator.onLine);
    }, []);

    useEffect(() => {
        // Check for touch device capability
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);

        // Debounce the handleResize function
        const debouncedHandleResize = debounce(handleResize, 100);

        // Add event listeners for resize, orientation change, and network status
        window.addEventListener('resize', debouncedHandleResize);
        window.addEventListener('orientationchange', handleOrientationChange);
        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOnlineStatusChange);

        // Initial detection when component mounts
        handleResize();
        setDeviceType(detectDeviceType()); // Call the detectDeviceType function here
        handleOrientationChange();
        handleOnlineStatusChange();

        // Cleanup listeners when component unmounts
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
        };
    }, [handleResize, handleOrientationChange, handleOnlineStatusChange, detectDeviceType]);

    return { isMobile, deviceType, deviceOrientation, deviceDimensions, isTouchDevice, isOnline };
};
