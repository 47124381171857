import React from 'react';
import Modal from 'components/UI/modal/Modal';
import { ToDoListItem } from '../../../../models/ToDoListItem';
import { useStores } from 'hooks/useStores';
import ToDoListItemForm from 'components/Forms/ToDoListItem/ToDoListItemForm';

interface SubtaskModalProps {
    page?: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (values: ToDoListItem) => void;
    onUpdateTask: (values: ToDoListItem) => Promise<void>;
    triggerSubmit: boolean;
}

const EditTaskModal: React.FC<SubtaskModalProps> = ({ page, open, onClose, onUpdateTask, triggerSubmit }) => {
    const { toDoStore } = useStores();

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={'Subtasks'}
            modalDimensions={{ width: 900, height: 600 }}
            onSubmit={() => {}}
            includeFooter
        >
            <ToDoListItemForm
                page={page}
                task={toDoStore.selectedTask}
                onModalSubmitted={onUpdateTask}
                submitting={triggerSubmit}
            />
        </Modal>
    );
};

export default EditTaskModal;
