import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../Header.style';

// SubHeaderContainer with positioning below the Header
const SubHeaderContainer = styled.div<{ visible: boolean }>`
    position: relative; // Ensure positioning is relative to its normal position
    background-color: transparent; // White background for a modern feel
    padding: 10px; // Increased padding for a spacious feel
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; // Smooth transitions
    overflow: hidden;
    opacity: ${({ visible }) => (visible ? 1 : 0)}; // Fading effect
    margin-top: ${HEADER_HEIGHT}px; // Space below the Header
    max-height: ${({ visible }) => (visible ? '70px' : '0')}; // Controlled height based on visibility
`;

const SubHeaderContent = styled.div`
    font-size: 14px; // Adjust font size for readability
    color: #333; // Darker text color
`;

interface SubHeaderProps {
    isVisible: boolean;
    content: React.ReactNode; // Accepts a React component as content
}

const SubHeader: React.FC<SubHeaderProps> = ({ isVisible, content }) => {
    return (
        <SubHeaderContainer visible={isVisible}>
            {isVisible && <SubHeaderContent>{content}</SubHeaderContent>} {/* Render the passed content */}
        </SubHeaderContainer>
    );
};

export default SubHeader;
