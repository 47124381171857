export const LANDSCAPE_DEVICE_CONDITION =
    '(min-device-width: 200px) and (max-device-width: 900px) and (max-device-height: 1000px) and (orientation: landscape) and (min-aspect-ratio: 13/9)';

const HTTP_PROTOCOL = 'http';
const WWW = 'www';

/**
 * Checks if the current browser is Internet Explorer (IE).
 * This includes IE 10 and older versions as well as IE 11.
 */
export function isIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
}

/**
 * Determines if the device is running iOS.
 * It includes iPhone, iPad, iPod, and also handles the detection of iPad in iOS 13+.
 */
export function iOS(): boolean {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13+ detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

/**
 * Determines if the device is an iPhone.
 */
export const isIphone = (): boolean => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !/Windows Phone/.test(navigator.userAgent);
};

/**
 * Determines if the device is running Android.
 */
export function android(): boolean {
    return navigator.userAgent.toLowerCase().includes('android') || navigator.platform.includes('Linux');
}

/**
 * Determines if the current browser is Firefox.
 */
export function isFirefox(): boolean {
    return navigator.userAgent.includes('Firefox');
}

/**
 * Determines if the device is running macOS.
 */
export function isMac(): boolean {
    return navigator.userAgent.includes('Mac OS X');
}

/**
 * Returns the current window dimensions.
 */
export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}

/**
 * Opens a URL in a new browser tab and focuses the tab.
 * @param {string} url - The URL to open.
 */
export function openInNewTab(url: string) {
    const win = window.open(url, '_blank');
    if (win) win.focus();
}

/**
 * Prepends the HTTP protocol to a URL if not already present.
 * Converts relative URLs to absolute ones for external links.
 * @param {string} url - The URL to modify.
 * @returns {string} - The modified URL with the protocol.
 */
export const prependHTTPProtocol = (url: string): string => {
    if (url.startsWith(HTTP_PROTOCOL)) {
        return url;
    }
    if (url.startsWith(WWW)) {
        return `${HTTP_PROTOCOL}://${url}`;
    }
    return `${HTTP_PROTOCOL}://${WWW}.${url}`;
};

/**
 * Detects if the user is using a mobile device.
 * Uses a combination of user agent, screen size, touch events, and CSS media queries.
 * @returns {boolean} - True if the user is using a mobile device.
 */
export const isUserUsingMobile = (): boolean => {
    // User agent string detection
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    // Screen resolution detection
    if (!isMobile) {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        isMobile = screenWidth < 768 || screenHeight < 768;
    }

    // Touch events detection
    if (!isMobile) {
        isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    // CSS media queries detection
    if (!isMobile) {
        const bodyElement = document.body;
        const content = window.getComputedStyle(bodyElement).getPropertyValue('content');
        isMobile = content.includes('mobile');
    }

    return isMobile;
};

/**
 * Debounce function to limit the rate at which a function can fire.
 * @param func - The function to debounce.
 * @param wait - The time to wait before calling the function again.
 * @param immediate - Whether to execute the function immediately.
 * @returns A debounced version of the provided function.
 */
export const debounce = (func: (...args: any[]) => void, wait: number, immediate: boolean = false) => {
    let timeout: NodeJS.Timeout | null;

    return function executedFunction(this: any, ...args: any[]) {
        const context = this;

        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
