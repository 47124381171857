import styled from 'styled-components';

export const CenterPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
`;

export const Message = styled.div`
    font-size: 2em; /* Increased font size for better readability */
    color: #333;
    text-align: center; /* Center text for better appearance */

    @media (max-width: 768px) {
        font-size: 1.5em; /* Adjust font size for smaller screens */
        padding: 0 10px; /* Add padding for small screens to prevent overflow */
    }
`;

export const AddToDoListItemButton = styled.button`
    height: 40px;
    padding: 0 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-left: auto; /* Automatically push to the right */
    display: flex;
    align-items: center;

    &:hover {
        background-color: #0056b3;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

    @media (max-width: 768px) {
        margin: 10px 0; /* Add margin for spacing on mobile */
        display: block; /* Ensure it behaves like a block element for full width */
        width: 100%; /* Make it take the full width on mobile */
        height: 50px; /* Slightly increase height for easier tap on mobile */
        font-size: 1.1rem; /* Increase font size for better visibility on smaller screens */
        padding: 0 15px; /* Adjust padding for a better look on mobile */
        border-radius: 6px; /* Slightly increase radius for a better look on mobile */
    }
`;

export const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        align-items: center; /* Center items on mobile */
    }
`;

export const Filters = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add gap for better spacing */
    margin-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column; /* Stack filters on mobile */
        align-items: center; /* Center filters on mobile */
    }
`;

export const Group = styled.div`
    margin-bottom: 20px; /* Add space between groups */
`;

export const GroupTitle = styled.h2`
    font-size: 1.5em; /* Increase title size */
    margin-bottom: 10px;
`;

export const Card = styled.div`
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ToggleButton = styled.button`
    margin-top: 10px; /* Add margin for better spacing */
    padding: 10px; /* Increase padding for a better tap target */
    font-size: 1em; /* Adjust font size */
`;

export const ExpandableContent = styled.div<{ expanded: boolean }>`
    display: ${({ expanded }) => (expanded ? 'block' : 'none')};
    margin-top: 10px; /* Add space above expandable content */
`;

export const NoTasksMessage = styled.p`
    text-align: center;
    color: #999; /* Lighter color for no tasks message */
`;
