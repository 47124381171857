import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import { IntlProvider } from 'react-intl';
import { IntlStore } from 'stores/IntlStore';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Loader from 'components/UI/Loader';
import ChangeAnswerSpinner from 'components/UI/ChangeAnswerSpinner';
import { createRoot } from 'react-dom/client';
import ApplicationLayout from 'components/ApplicationLayout/ApplicationLayout';
import { FlipsDesign } from 'theme/FlipsDesign';
import { MEDIUM_DEVICE_WIDTH } from 'hooks/useDeviceInfo';

const WorkExperience = lazy(() => import('./components/Pages/Home/WorkExperience/WorkExperience'));
const Lola = lazy(() => import('./components/Pages/Lola/Lola'));
const FinanceDetails = lazy(() => import('./components/Pages/FinanceDetails/FinanceDetails'));
const Art = lazy(() => import('./components/Pages/Home/AboutMe/Art/Art'));
const BusinessStrategy = lazy(() => import('./components/Pages/Home/AboutMe/BusinessStrategy/BusinessStrategy'));
const CareerGoals = lazy(() => import('./components/Pages/Home/AboutMe/CareerGoals/CareerGoals'));
const Finances = lazy(() => import('./components/Pages/Home/AboutMe/Finances/Finances'));
const WorkDetails = lazy(() => import('./components/Pages/Home/WorkExperience/WorkDetails/WorkDetails'));
const Home = lazy(() => import('./components/Pages/Home/Home'));
const ToDoPage = lazy(() => import('./components/Pages/ToDo/ToDoPage'));

const Education = lazy(() => import('./components/Pages/Home/Education/Education'));
const Contact = lazy(() => import('./components/Pages/Contact/Contact'));
const Events = lazy(() => import('./components/Pages/Events/Events'));
const CartPage = lazy(() => import('./components/Pages/ShoppingCart/ShoppingCart'));
const ProductsPage = lazy(() => import('./components/Pages/Products/ProductList'));

const App = () => {
    const { themeStore, intlStore } = useStores();

    return (
        <BrowserRouter>
            <IntlProvider
                locale={intlStore.locale}
                messages={intlStore.messages}
                defaultLocale={IntlStore.DEFAULT_LOCAL}
            >
                <ThemeProvider theme={FlipsDesign}>
                    <GlobalStyle theme={FlipsDesign} />

                    {themeStore.isLoadingTheme && <Loader visible={themeStore.isLoadingTheme} />}
                    <ApplicationLayout>
                        <Suspense fallback={<ChangeAnswerSpinner visible />}>
                            <Routes>
                                <Route path="/home" element={<Home />} />
                                <Route path="/home/workexperience" element={<WorkExperience />} />
                                <Route path="/home/workexperience/:name" element={<WorkDetails />} />
                                <Route path="/home/education" element={<Education />} />
                                <Route path="/home/finances" element={<Finances />} />
                                <Route path="/home/finances/:name" element={<FinanceDetails />} />
                                <Route path="/home/careergoals" element={<CareerGoals />} />
                                <Route path="/home/businessstrategy" element={<BusinessStrategy />} />
                                <Route path="/home/art" element={<Art />} />

                                <Route path="/contact" element={<Contact />} />
                                <Route path="/events" element={<Events />} />
                                <Route path="/investments" element={<ToDoPage />} />
                                <Route path="/newsFeed" element={<ToDoPage />} />
                                <Route path="/endeavors" element={<ToDoPage />} />
                                <Route path="/projects" element={<ToDoPage />} />
                                <Route path="/references" element={<ToDoPage />} />
                                <Route path="/cart" element={<CartPage />} />
                                <Route path="/products" element={<ProductsPage />} />
                                <Route path="/Lola" element={<Lola />} />

                                <Route path="*" element={<Home/>} />
                            </Routes>
                        </Suspense>
                    </ApplicationLayout>
                </ThemeProvider>
            </IntlProvider>
        </BrowserRouter>
    );
};

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);


const GlobalStyle = createGlobalStyle<{ theme: typeof FlipsDesign }>`
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html, body {
    font-family: Montserrat, sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: #979797;

    @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        overscroll-behavior: none;
    }
}

h2 {
    font-family: Arial, sans-serif;
    font-weight: normal;
    font-size: 1.5rem; 
    font-style: normal;
    margin-bottom: 1rem;

    @media (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        font-size: 1.25rem; 
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: inherit;
}

::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 3px;
}
`;
