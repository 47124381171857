import React, { useState, useEffect } from 'react';
import { FooterContainer } from './Footer.style';
import ToDoList from 'components/Pages/ToDo/ToDoList/ToDoList';
import { useLocation } from 'react-router-dom';
import { navigationLinks } from 'components/Header/Header';
import { useStores } from 'hooks/useStores';
import { isEmpty } from 'lodash';
import { ToDoListItem } from 'models/ToDoListItem';

const Footer = () => {
    const location = useLocation();
    const { toDoStore } = useStores();
    const currentLink = navigationLinks.find((page) => location.pathname.includes(page.to))?.key || 'Projects';
    const [isVisible, setIsVisible] = useState(false);
    const [pageToDoList, setPageToDoList] = useState<ToDoListItem[]>([]);

    useEffect(() => {
        const initializeToDoList = async () => {
            if (isEmpty(toDoStore.toDoItems) && !toDoStore.isLoading && currentLink !== 'Projects') {
                await toDoStore.fetchToDoList();
                setPageToDoList(toDoStore.getIncompleteTasksForPage(currentLink));
            }
        };

        initializeToDoList();
    }, [currentLink, toDoStore]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            const isToggleShortcut =
                ((event.ctrlKey || event.metaKey) && event.key === 'T') ||
                ((event.ctrlKey || event.metaKey) && event.altKey && event.key === 'T');

            if (isToggleShortcut) {
                setIsVisible((prev) => !prev); // Toggle visibility
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, []);

    useEffect(() => {
        if (!isEmpty(toDoStore.toDoItems)) {
            setPageToDoList(toDoStore.getIncompleteTasksForPage(currentLink));
        }

        //eslint-disable-next-line
    }, [toDoStore.toDoItems, currentLink]);

    if (!isVisible) return null;

    return <FooterContainer>{<ToDoList pageItems={pageToDoList} page={currentLink} />}</FooterContainer>;
};

export default Footer;
