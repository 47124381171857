import styled from 'styled-components';
import { TaskPriority } from '../../../../models/ToDoListItem';

// Styled Components
export const Container = styled.div`
    width: 100%;
    background-color: #191919;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px; /* Add this line for space at the bottom */

    @media (max-width: 600px) {
        padding: 15px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Filters = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const Label = styled.label`
    font-weight: bold;
`;

export const Select = styled.select`
    margin-left: 10px;
    padding: 5px;
`;

export const Content = styled.div`
    max-height: 400px; // Set a maximum height for scrolling
    overflow-y: auto; // Allow vertical scrolling

    @media (max-width: 600px) {
        max-height: 300px; // Reduce max height for mobile
    }
`;

export const NoTasksMessage = styled.p`
    text-align: center;
    color: #bdc3c7; // Light gray color for no tasks message
    font-size: 1.2em;

    @media (max-width: 600px) {
        font-size: 1em; // Reduce font size for mobile
    }
`;

export const Group = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

export const GroupTitle = styled.h3`
    margin: 20px 0 10px;
    color: #f39c12; /* Color for group titles */
`;

export const Card = styled.div`
    background-color: #272727;
    border-radius: 12px;
    padding: 20px;
    margin: 10px 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
        transform: translateY(-2px);
    }

    @media (max-width: 600px) {
        padding: 15px; // Reduce padding for mobile
    }
`;

export const Badge = styled.span<{ $priority: TaskPriority }>`
    background-color: ${({ $priority }) =>
        $priority === TaskPriority.HIGH ? '#e74c3c' : $priority === TaskPriority.MEDIUM ? '#f1c40f' : '#2ecc71'};
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    margin-left: 10px;
    font-weight: bold;

    @media (max-width: 600px) {
        padding: 4px 8px; // Reduce padding for mobile
    }
`;

export const Item = styled.div`
    padding: 10px;

    @media (max-width: 600px) {
        padding: 8px; // Reduce padding for mobile
    }
`;

export const Title = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 1.2em; // Reduce font size for mobile
    }
`;

export const Description = styled.p`
    font-size: 1em;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        font-size: 0.9em; // Reduce font size for mobile
    }
`;

export const Status = styled.p<{ $completed: boolean }>`
    color: ${({ $completed }) => ($completed ? '#2ecc71' : '#e74c3c')};
    font-weight: bold;
`;

export const DueDate = styled.p`
    font-size: 0.9em;
    color: #bdc3c7;
    margin: 5px 0;

    @media (max-width: 600px) {
        font-size: 0.8em; // Reduce font size for mobile
    }
`;

export const ExpandableContent = styled.div<{ $expanded: boolean }>`
    max-height: ${({ $expanded }) => ($expanded ? '200px' : '0')};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
`;

export const ToggleButton = styled.button`
    background-color: #1abc9c;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background-color: #16a085;
    }

    @media (max-width: 600px) {
        padding: 8px; // Reduce padding for mobile
    }
`;

export const DetailSection = styled.div`
    margin-top: 10px;
    color: #ecf0f1;

    strong {
        display: block;
        margin-bottom: 5px;
    }

    a {
        color: #1abc9c;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;
