import { makeAutoObservable, observable } from 'mobx';
import { ToDoListItem } from 'models/ToDoListItem';
import { toDoListApi } from 'api/flipTheCoders';
import { Subtask } from 'models/Subtask';

export class ToDoStore {
    isLoading: boolean = false;
    selectedTask: ToDoListItem | undefined;
    @observable toDoItems: ToDoListItem[] = [];
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getCompletedTasksForPage = (page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => item.completed && (page ? item.page === page : true));
    };

    getIncompleteTasksForPage = (page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => !item.completed && (page !== undefined ? item.page === page : true));
    };

    get completedTasks(): ToDoListItem[] {
        return this.toDoItems.filter((item) => item.completed);
    }

    get incompleteTasks(): ToDoListItem[] {
        return this.toDoItems.filter((item) => !item.completed);
    }

    getTasksForPage = (completed: boolean, page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => item.completed === completed && (page ? item.page === page : true));
    };

    fetchToDoList = async () => {
        this.setIsLoading(true);
        try {
            const response = await toDoListApi.getAll();
            this.setToDoItems(response.data);
        } catch (error) {
            this.setError('Failed to fetch to-do list');
            console.error('Error fetching to-do list:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    createTask = async (newTask: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            const response = await toDoListApi.create(newTask);
            this.addToDoItem(response.data);
        } catch (error) {
            this.setError('Failed to create task');
            console.error('Error creating task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    updateTask = async (updatedTask: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.update(updatedTask.id, updatedTask);
            this.replaceToDoItem(updatedTask);
        } catch (error) {
            this.setError('Failed to update task');
            console.error('Error updating task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deleteTask = async (taskId: string) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.delete(taskId);
            this.removeToDoItem(taskId);
        } catch (error) {
            this.setError('Failed to delete task');
            console.error('Error deleting task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    completeTask = async (task: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.complete(task.id);
            this.markTaskAsComplete(task.id);
        } catch (error) {
            this.setError('Failed to complete task');
            console.error('Error completing task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    // Subtask Management
    createSubTask = async (taskId: string, newSubTask: Subtask) => {
        this.setIsLoading(true);
        try {
            const response = await toDoListApi.addSubTask(taskId, newSubTask);
            this.addSubTaskToTask(taskId, response.data);
        } catch (error) {
            this.setError('Failed to create subtask');
            console.error('Error creating subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    updateSubTask = async (taskId: string, updatedSubTask: Subtask) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.updateSubTask(taskId, updatedSubTask.id, updatedSubTask);
            this.replaceSubTaskInTask(taskId, updatedSubTask);
        } catch (error) {
            this.setError('Failed to update subtask');
            console.error('Error updating subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deleteSubTask = async (taskId: string, subTaskId: string) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.deleteSubTask(taskId, subTaskId);
            this.removeSubTaskFromTask(taskId, subTaskId);
        } catch (error) {
            this.setError('Failed to delete subtask');
            console.error('Error deleting subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    private addToDoItem(item: ToDoListItem) {
        this.toDoItems.push(item);
    }

    private replaceToDoItem(updatedItem: ToDoListItem) {
        this.toDoItems = this.toDoItems.map((item) => (item.id === updatedItem.id ? updatedItem : item));
    }

    private removeToDoItem(taskId: string) {
        this.toDoItems = this.toDoItems.filter((item) => item.id !== taskId);
    }

    private markTaskAsComplete(taskId: string) {
        this.toDoItems = this.toDoItems.map((item) => (item.id === taskId ? { ...item, completed: true } : item));
    }

    private addSubTaskToTask(taskId: string, subTask: Subtask) {
        const task = this.toDoItems.find((item) => item.id === taskId);
        if (task) {
            task.subtasks?.push(subTask);
        }
    }

    private replaceSubTaskInTask(taskId: string, updatedSubTask: Subtask) {
        const task = this.toDoItems.find((item) => item.id === taskId);
        if (task) {
            task!.subtasks = task.subtasks?.map((subTask) =>
                subTask.id === updatedSubTask.id ? updatedSubTask : subTask
            );
        }
    }

    private removeSubTaskFromTask(taskId: string, subTaskId: string) {
        const task = this.toDoItems.find((item) => item.id === taskId);
        if (task) {
            task.subtasks = task.subtasks!.filter((subTask) => subTask!.id !== subTaskId);
        }
    }

    private setToDoItems(items: ToDoListItem[]) {
        this.toDoItems = items;
    }

    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    private setError(message: string) {
        this.error = message;
    }

    setSelectedTask(value: ToDoListItem | undefined) {
        this.selectedTask = value;
    }
}
