import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { StyledFormContainer, ErrorMessageStyled, FieldWrapper } from './SubtaskForm.style';
import { Subtask } from '../../../models/Subtask';
import { Tag, TaskPriority } from 'models/ToDoListItem';
import { StyledLabel } from '../ToDoListItem/ToDoListItemForm.style';

const validationSchema = Yup.object().shape({
    id: Yup.number().required('Id is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
});

const SubtaskForm = ({ onModalSubmitted }) => {
    const [formValues, setFormValues] = useState<Subtask>({
        id: '',
        title: '',
        description: '',
        completed: false,
        createdAt: new Date(),
        priority: TaskPriority.LOW,
        tag: Tag.TASK,
    });

    const onSubmit = () => {
        console.log(formValues);
    };

    useEffect(() => {
        if (onModalSubmitted) {
            onSubmit();
        }
        //eslint-disable-next-line
    }, [onModalSubmitted]);

    return (
        <StyledFormContainer>
            <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setFormValues(values);
                    onSubmit();
                }}
            >
                {() => (
                    <Form>
                        <FieldWrapper>
                            <StyledLabel>Id</StyledLabel>
                            <Field name="id" type="number" />
                            <ErrorMessage name="id" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Title</StyledLabel>
                            <Field name="title" type="text" />
                            <ErrorMessage name="title" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Description</StyledLabel>
                            <Field name="description" type="text" />
                            <ErrorMessage name="description" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Completed</StyledLabel>
                            <Field name="completed" type="checkbox" />
                            <ErrorMessage name="completed" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>CreatedAt</StyledLabel>
                            <Field name="createdAt" type="number" />
                            <ErrorMessage name="createdAt" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Priority</StyledLabel>
                            <Field name="priority" type="number" />
                            <ErrorMessage name="priority" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Tag</StyledLabel>
                            <Field name="tag" type="number" />
                            <ErrorMessage name="tag" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <button type="submit">Submit</button>
                    </Form>
                )}
            </Formik>
        </StyledFormContainer>
    );
};

export default SubtaskForm;
