import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Dimensions } from 'models/Dimensions';

interface ModalProps {
    title: string;
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    modalDimensions?: Dimensions;
    onSubmit?: () => void;
    cancelButtonColor?: string;
    submitButtonColor?: string;
    includeFooter: boolean;
}

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div<{ dimensions: Dimensions }>`
    background-color: white;
    width: ${({ dimensions }) => `${dimensions.width}px` || '80vw'};
    max-width: 90vw;
    max-height: 75vh;
    min-width: 300px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    color: black;

    h2 {
        margin: 0;
        font-size: 1.75rem;
        font-weight: bold;
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #888;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: color 0.3s;

    &:hover {
        color: #ff4d4d;
    }

    &:focus {
        outline: none;
    }
`;

const ModalBody = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px 0;
    padding-right: 20px;
    max-height: calc(75vh - 80px); /* Allow for title and footer */
    /* Ensure the modal body allows scroll */
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 20px;
`;

const Button = styled.button<{ bgColor: string }>`
    background-color: ${({ bgColor }) => bgColor};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        transform: translateY(-2px);
        filter: brightness(0.9);
    }

    &:active {
        transform: translateY(0);
    }

    &:focus {
        outline: none;
    }
`;

const Modal: React.FC<ModalProps> = ({
    title,
    modalDimensions = { width: 400, height: 900 },
    open,
    onClose,
    children,
    onSubmit,
    includeFooter,
    cancelButtonColor = '#ff4d4d',
    submitButtonColor = '#28a745',
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, onClose]);

    if (!open) return null;

    return (
        <ModalOverlay>
            <ModalContainer
                ref={modalRef}
                dimensions={modalDimensions}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-title"
            >
                <ModalHeader>
                    <h2 id="modal-title">{title}</h2>
                    <CloseButton aria-label="Close modal" onClick={onClose}>
                        &times;
                    </CloseButton>
                </ModalHeader>

                <ModalBody>{children}</ModalBody>

                {includeFooter && (
                    <ModalFooter>
                        <Button bgColor={cancelButtonColor} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button bgColor={submitButtonColor} onClick={onSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                )}
            </ModalContainer>
        </ModalOverlay>
    );
};

export default Modal;
