import React from 'react';
import { AuthStore } from '../stores/AuthStore';
import { UserStore } from '../stores/UserStore';
import { IntlStore } from '../stores/IntlStore';
import { CartStore } from '../stores/CartStore';
import { ThemeStore } from '../stores/ThemeStore';
import { ToDoStore } from '../stores/ToDoStore';
import { FinancesStore } from '../stores/FinancesStore';

export const storesContext = React.createContext({
    authStore: new AuthStore(),
    intlStore: new IntlStore(),
    userStore: new UserStore(),
    cartStore: new CartStore(),
    themeStore: new ThemeStore(),
    toDoStore: new ToDoStore(),
    financeStore: new FinancesStore(),
});
