import styled from 'styled-components';

export const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%; /* Let the container use the full height of its parent */
    overflow-y: auto; /* Enable scrolling */
    padding: 1rem; /* Adjust padding */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    scroll-behavior: smooth; /* Enable smooth scrolling */

    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
    -ms-overflow-style: none;
`;

export const FieldWrapper = styled.div`
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Input = styled.input`
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    width: 100%;
    box-sizing: border-box;

    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

export const TextArea = styled.textarea`
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;

    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

export const ErrorMessageStyled = styled.div`
    color: #ff4d4d;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: rgba(255, 77, 77, 0.1);
    border-radius: 4px;
`;
