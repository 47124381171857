import React from 'react';
import Header from '../Header/Header';
import styled from 'styled-components';
import { HEADER_HEIGHT } from 'components/Header/Header.style';
import Footer from 'components/Footer/Footer';

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 40px;

    @media (max-width: 768px) {
        padding-bottom: calc(${HEADER_HEIGHT}px + 40px);
    }
`;

const ContentContainer = styled.div`
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const ApplicationLayout = ({ children }) => {
    return (
        <LayoutContainer>
            <Header />
            <ContentContainer>{children}</ContentContainer>
            <Footer />
        </LayoutContainer>
    );
};

export default ApplicationLayout;
