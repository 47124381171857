import { keyframes } from 'styled-components';

// Keyframes for shake animation
export const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  20%, 80% {
    transform: translateX(-6px);
  }
  40%, 60% {
    transform: translateX(6px);
  }
`;

export const spin = keyframes`
    from{transform: rotate(0deg);}
    to{transform: rotate(360deg);}
`;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;


export const fadeInTranslation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
