import styled from 'styled-components';

export const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 1.5rem;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
`;

export const FieldWrapper = styled.div`
    margin-bottom: 1.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Input = styled.input`
    padding: 0.85rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s ease-in-out;
    width: 100%;
    box-sizing: border-box;

    &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
    }
`;

export const ErrorMessageStyled = styled.div`
    color: #ff4d4d;
    font-size: 0.875rem;
    margin-top: 0.25rem;
`;

export const StyledLabel = styled.label`
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #333;
`;

export const StyledSelect = styled.select`
    padding: 0.85rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s ease-in-out;

    &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
    }
`;

/* Checkbox Wrapper */
export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const CheckboxLabel = styled.label`
    font-size: 1rem;
    margin-left: 0.5rem; /* Space between checkbox and label */
    color: #333;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export const StyledButton = styled.button`
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-right: 1rem; /* Add margin between buttons */

    &:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }

    &:disabled {
        background-color: #cccccc; /* Grey out if disabled */
        cursor: not-allowed;
    }
`;

export const CancelButton = styled(StyledButton)`
    background-color: #dc3545; /* Red background for cancel */

    &:hover {
        background-color: #c82333; /* Darker red on hover */
    }
`;
