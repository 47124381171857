import { AxiosPromise } from 'axios';
import { Transport } from './Transport/Transport';
import { User } from 'models/User';
import { Subtask, ToDoListItem } from 'models/ToDoListItem';

let API_ROOT_PATH = 'http://flipthecoders.com/api';
//API_ROOT_PATH = 'http://localhost:8080/api';

export const flipTheCoders = {
    verifyConnection: (): AxiosPromise<string> => {
        return Transport.get(`${API_ROOT_PATH}/initialize/healthcheck`);
    },

    getAllUsers: (): AxiosPromise<User[]> => {
        return Transport.get(`${API_ROOT_PATH}/users`);
    },
};

export const toDoListApi = {
    getAll: (): AxiosPromise<ToDoListItem[]> => {
        return Transport.get(`${API_ROOT_PATH}/todos`);
    },

    create: (toDoListItem: ToDoListItem): AxiosPromise<ToDoListItem> => {
        return Transport.post(`${API_ROOT_PATH}/todos`, toDoListItem);
    },

    findById: (id: string): AxiosPromise<ToDoListItem> => {
        return Transport.get(`${API_ROOT_PATH}/todos/${id}`);
    },

    update: (id: string, toDoListItem: ToDoListItem): AxiosPromise<ToDoListItem> => {
        return Transport.put(`${API_ROOT_PATH}/todos/${id}`, toDoListItem);
    },

    delete: (id: string): AxiosPromise<void> => {
        return Transport.delete(`${API_ROOT_PATH}/todos/${id}`);
    },

    complete: (id: string): AxiosPromise<void> => {
        return Transport.post(`${API_ROOT_PATH}/todos/${id}/complete`);
    },

    addSubTask: (taskId: string, subTask: Subtask): AxiosPromise<Subtask> => {
        return Transport.post(`${API_ROOT_PATH}/todos/${taskId}/subtasks`, subTask);
    },

    updateSubTask: (taskId: string, subTaskId: string, subTask: Subtask): AxiosPromise<Subtask> => {
        return Transport.put(`${API_ROOT_PATH}/todos/${taskId}/subtasks/${subTaskId}`, subTask);
    },

    deleteSubTask: (taskId: string, subTaskId: string): AxiosPromise<void> => {
        return Transport.delete(`${API_ROOT_PATH}/todos/${taskId}/subtasks/${subTaskId}`);
    },
};
