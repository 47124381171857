import { MEDIUM_DEVICE_WIDTH } from 'hooks/useDeviceInfo';
import { styled } from 'styled-components';

export const HEADER_HEIGHT = 80;

const NavigationBar = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #333;
    box-shadow: 0px 3px 4px 0px #00000024, 0px 3px 11px -2px #00000003;
    height: ${HEADER_HEIGHT}px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
`;

const LogoWrapper = styled.div`
    width: 350px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    img {
        height: ${HEADER_HEIGHT}px;
        width: 256px;
        align-self: center;

        @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
            height: 65px;
            width: 200px;
        }
    }
`;

const NavigationLinksContainer = styled.ul<{ open: boolean }>`
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-right: 16px;
    list-style: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

    @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        flex-direction: column;
        position: fixed;
        top: ${HEADER_HEIGHT}px;
        right: 0;
        width: 100vw;
        border-left: 1px solid grey;
        padding: 1rem;
        z-index: 1000;
        max-height: ${({ open }) => (open ? '500px' : '0')};
        opacity: ${({ open }) => (open ? 1 : 0)};
        background: #34495e;
    }

    @media only screen and (min-width: ${MEDIUM_DEVICE_WIDTH + 1}px) {
        display: flex;
        flex-direction: row;
        position: static;
        max-height: none;
        opacity: 1;
        z-index: auto;
    }
`;

const BurgerMenu = styled.div<{ open: boolean; touchAction?: string; pointerEvents?: string }>`
    display: none;

    @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        display: flex;
        flex-direction: column;
        cursor: pointer; // Retain cursor pointer style
        margin-left: auto;
        margin-right: 8px;
        position: relative;
        width: 30px;
        height: 20px;

        // Customizable pointer-events and touch-action
        pointer-events: ${({ open, pointerEvents }) => pointerEvents || (open ? 'none' : 'auto')};
        touch-action: ${({ touchAction }) => touchAction || 'auto'}; // Default touch action

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            background: white;
            transition: 0.3s;
            left: 0;
        }

        &::before {
            top: ${({ open }) => (open ? '50%' : '0')};
            transform: ${({ open }) => (open ? 'translateY(-50%) rotate(45deg)' : 'none')};
        }

        &::after {
            bottom: ${({ open }) => (open ? '50%' : '0')};
            transform: ${({ open }) => (open ? 'translateY(50%) rotate(-45deg)' : 'none')};
        }

        & > div {
            position: absolute;
            width: 25px;
            height: 2px;
            background: white;
            transition: 0.3s;
            top: 50%;
            transform: ${({ open }) =>
                open ? 'translateY(-50%) scale(0)' : 'translateY(-50%)'}; // Hide the middle bar
        }
    }
`;

const LinkOption = styled.li`
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.15s;
    letter-spacing: 1.28px;
    margin-left: 20px;
    text-align: center;
    opacity: 1;

    a {
        color: #ecf0f1;
        opacity: 0.7;
        text-decoration: none;
        transition: opacity 0.2s ease-in-out;

        &.active,
        :hover {
            opacity: 1;
        }

        &.active {
            font-weight: 500;
            color: #e74c3c;
        }
    }

    &.active {
        box-shadow: inset 0 -4px 0 #e74c3c;
    }

    @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        margin-left: 0;
        padding: 15px;
    }
`;

const Icon = styled.div`
    display: none;

    @media only screen and (max-width: ${MEDIUM_DEVICE_WIDTH}px) {
        display: inline-flex; /* Show icons only on mobile screens */
        margin-right: 8px;
    }
`;

const HeaderContainer = styled.div<{ subheader: boolean }>`
    margin-top: ${({ subheader }) => (subheader ? '0px' : `${HEADER_HEIGHT}px`)};
`;

const HeaderStyles = {
    NavigationBar,
    LogoWrapper,
    BurgerMenu,
    LinkOption,
    NavigationLinksContainer,
    Icon,
    HeaderContainer,
};

export default HeaderStyles;
