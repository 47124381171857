export const formatDate = (dueDate: Date) => {
    const date = new Date(dueDate);
    return date.toLocaleDateString('en-US', {
        weekday: 'short', // e.g., Mon
        year: 'numeric', // e.g., 2024
        month: 'short', // e.g., Oct
        day: 'numeric', // e.g., 14
        hour: '2-digit', // e.g., 07
        minute: '2-digit', // e.g., 00
        hour12: true, // Change to true for 12-hour format
    });
};
