import styled from 'styled-components';

export const HomePageSubHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between; // Align buttons to the start
    align-items: center;
    height: 100%;
    padding: 20px;
    background-color: transparent;

    // Optional: Style the buttons directly if needed
    button {
        margin: 0 5px; // Adjust margin for closer spacing
    }
`;
