// Add TypeScript to FrontendTechnologies enum
export enum FrontendTechnologies {
    HTML = 'HTML',
    CSS = 'CSS',
    JavaScript = 'JavaScript',
    TypeScript = 'TypeScript', // Added this
    React = 'React',
    ReactHooks = 'React Hooks',
    ReactContext = 'React Context',
    ReactRouter = 'React Router',
    Redux = 'Redux',
}

// Add missing backend and DevOps technologies
export enum BackendTechnologies {
    SpringBoot = 'Spring Boot',
    Hibernate = 'Hibernate',
    ASPNET = 'ASP.NET',
    EntityFramework = 'Entity Framework',
    Java = 'Java', // Added this
    CSharp = 'C#',
    Microservices = 'Microservices', // Added this
}

export enum DevOpsTechnologies {
    Docker = 'Docker',
    Kubernetes = 'Kubernetes',
    Jenkins = 'Jenkins',
    AzureSQL = 'Azure SQL',
    AzureCosmosDB = 'Azure Cosmos DB',
    AzurePipelines = 'Azure Pipelines',
    PostgreSQL = 'PostgreSQL',
    Bitbucket = 'Bitbucket', // Added this
    AzureDevOps = 'Azure Dev Ops',
    AWSCodePipeline = 'AWS Code Pipeline',
    AWSCodeDeploy = 'AWS Code Deploy',
    AzureARM = 'AzureARM',
    Terraform = 'Terraform',
}

// Define GitTechnologies
export enum GitTechnologies {
    GitCLI = 'Git CLI',
    GitHub = 'GitHub',
    GitLab = 'GitLab',
    Bitbucket = 'Bitbucket',
    GitFlow = 'GitFlow', // Added this as it was referenced
}

// Added the WorkExperienceAspect enum for categorizing experience
export enum WorkExperienceAspect {
    FrontEnd = 'FRONTEND',
    BackEnd = 'BACKEND',
    GIT = 'GIT',
    DevOps = 'DEVOPS',
    Azure = 'AZURE',
    AWS = 'AWS',
}

// Interface for each technology
export interface Technology {
    name: string;
    use: string;
}

// Interface for each technology
export interface Question {
    question: string;
    answer: string;
}

// Interface for each experience entry
export interface Experience {
    id: string;
    name: string;
    framework: string;
    ide: string;
    technologies: Technology[];
    certifications?: string[];
    trainings?: string[];
    databaseManagement?: string;
    hosting?: string;
    benefits?: string[];
    automation?: string;
    useCases?: string[];
    nextSteps?: string[];
    scalability?: string;
    reporting?: string;
    questions?: Question[];
}

// Category interface to group experiences
export interface ExperienceCategory {
    [key: string]: { experience: Experience };
}
