import React from 'react';
import styled from 'styled-components';

interface Props {
    width?: string;
    height?: string;
    spinnerSize?: string;
    visible: boolean;
    showText?: boolean;
    loadingMessage?: string;
}

const Loader: React.FC<Props> = () => {
    return <Container />;
};

export default Loader;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    display: inline-block;
    z-index: -1;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.75);
    @media only screen and (max-width: 1025px) {
        width: 100vw;
        height: 100vh;
    }

    /* Landscape */
    @media only screen and (min-device-width: 200px) and (max-device-width: 900px) and (max-device-height: 1000px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
        top: 0px;
    }
`;
