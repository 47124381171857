function isNotProduction() {
    return process.env.NODE_ENV !== 'production';
}

function getFlipsRootPath() {
    let FLIPS_ROOT_PATH: string | undefined = '___ENV-VAR-FLIPS-ROOT-PATH___';

    if (process.env.REACT_APP_FLIPS_ROOT_PATH && isNotProduction()) {
        FLIPS_ROOT_PATH = process.env.REACT_APP_FLIPS_ROOT_PATH;
    }

    return FLIPS_ROOT_PATH;
}

// eslint-disable-next-line
export default {
    isNotProduction,
    getFlipsRootPath,
};
