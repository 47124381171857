import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    ErrorMessageStyled,
    FieldWrapper,
    StyledFormContainer,
    Input,
    StyledLabel,
    StyledSelect,
} from './ToDoListItemForm.style';
import { TaskPriority, WorkAspect, Tag, ToDoListItem } from '../../../models/ToDoListItem';
import { TextArea } from '../UpcomingEventForm/UpcomingEventForm.style';
import { WorkExperienceAspect } from 'models/WorkExperience';
import { NavigationLinkKey } from 'components/Header/Header';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    priority: Yup.string().required('Priority is required'),
    dueDate: Yup.date().nullable(),
    resourceLink: Yup.string().url('Invalid URL'),
    tag: Yup.string().required('Tag is required'),
    aspect: Yup.string().required('Aspect is required'),
});

interface ToDoListItemFormProps {
    task?: ToDoListItem;
    page?: string;
    submitting: boolean;
    onModalSubmitted: (updatedTask: ToDoListItem) => Promise<void>;
}

const ToDoListItemForm: React.FC<ToDoListItemFormProps> = ({ page, task, submitting, onModalSubmitted }) => {
    const [formValues, setFormValues] = useState<ToDoListItem>({
        id: '',
        title: '',
        description: '',
        priority: TaskPriority.LOW,
        dueDate: null,
        resourceLink: '',
        tag: Tag.TASK,
        aspect: WorkAspect.FrontEnd,
        completed: false,
        page: page || '',
    });

    useEffect(() => {
        setFormValues({
            id: task?.id || '',
            title: task?.title || '',
            description: task?.description || '',
            priority: task?.priority || TaskPriority.LOW,
            dueDate: task?.dueDate || null,
            resourceLink: task?.resourceLink || '',
            tag: task?.tag || Tag.TASK,
            aspect: task?.aspect || WorkAspect.FrontEnd,
            page: task?.page || page || '',
            completed: false,
        });
    }, [task, page]);

    useEffect(() => {
        const submit = async () => {
            await onModalSubmitted(formValues);
        };

        submitting && submit();
        //eslint-disable-next-line
    }, [submitting]);

    return (
        <StyledFormContainer>
            <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={() => {}}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <FieldWrapper>
                            <StyledLabel>Title</StyledLabel>
                            <Field
                                name="title"
                                as={Input}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    // setFieldValue('title', e.target.value);
                                    setFormValues({ ...formValues, title: e.target.value });
                                }}
                            />
                            <ErrorMessage name="title" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Description</StyledLabel>
                            <Field
                                name="description"
                                as={TextArea}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    // setFieldValue('description', e.target.value);
                                    setFormValues({ ...formValues, description: e.target.value });
                                }}
                            />
                            <ErrorMessage name="description" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Due Date</StyledLabel>
                            <Field
                                name="dueDate"
                                as={Input}
                                type="date"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    // setFieldValue('dueDate', e.target.value);
                                    setFormValues({ ...formValues, dueDate: e.target.value as unknown as Date });
                                }}
                            />
                            <ErrorMessage name="dueDate" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Aspect</StyledLabel>
                            <Field
                                as={StyledSelect}
                                name="aspect"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    // setFieldValue('aspect', e.target.value);
                                    setFormValues({ ...formValues, aspect: e.target.value as WorkAspect });
                                }}
                            >
                                <option value={WorkExperienceAspect.FrontEnd}>Front End</option>
                                <option value={WorkExperienceAspect.BackEnd}>Back End</option>
                                <option value={WorkExperienceAspect.Azure}>Azure</option>
                                <option value={WorkExperienceAspect.AWS}>AWS</option>
                                <option value={WorkExperienceAspect.GIT}>Git</option>
                            </Field>
                            <ErrorMessage name="aspect" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Priority</StyledLabel>
                            <Field
                                as={StyledSelect}
                                name="priority"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    // setFieldValue('priority', e.target.value);
                                    setFormValues({ ...formValues, priority: e.target.value as TaskPriority });
                                }}
                            >
                                <option value={TaskPriority.LOW}>Low</option>
                                <option value={TaskPriority.MEDIUM}>Medium</option>
                                <option value={TaskPriority.HIGH}>High</option>
                            </Field>
                            <ErrorMessage name="priority" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Tag</StyledLabel>
                            <Field
                                as={StyledSelect}
                                name="tag"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    // setFieldValue('tag', e.target.value);
                                    setFormValues({ ...formValues, tag: e.target.value as Tag });
                                }}
                            >
                                <option value={Tag.TASK}>Task</option>
                                <option value={Tag.BUG}>Bug</option>
                                <option value={Tag.STORY}>Story</option>
                            </Field>
                            <ErrorMessage name="tag" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Resource Link</StyledLabel>
                            <Field
                                name="resourceLink"
                                as={Input}
                                type="url"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    // setFieldValue('resourceLink', e.target.value);
                                    setFormValues({ ...formValues, resourceLink: e.target.value });
                                }}
                            />
                            <ErrorMessage name="resourceLink" component={ErrorMessageStyled} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <StyledLabel>Page</StyledLabel>
                            <Field
                                name="page"
                                as="select"
                                disabled={!!page} // Disable field if page is passed
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    setFormValues({ ...formValues, page: e.target.value });
                                }}
                            >
                                <option value="">Select a Page</option>
                                {Object.values(NavigationLinkKey).map((link) => (
                                    <option key={link} value={link}>
                                        {link}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="page" component={ErrorMessageStyled} />
                        </FieldWrapper>
                    </Form>
                )}
            </Formik>
        </StyledFormContainer>
    );
};

export default ToDoListItemForm;
