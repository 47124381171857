import React from 'react';
import Modal from 'components/UI/modal/Modal';
import SubtaskForm from 'components/Forms/Subtask/SubtaskForm';
import { Tag, TaskPriority, ToDoListItem } from '../../../../models/ToDoListItem';
import { useStores } from 'hooks/useStores';

interface SubtaskModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: ToDoListItem) => void;
}

const SubtaskModal: React.FC<SubtaskModalProps> = ({ open, onClose, onSubmit }) => {
    const { toDoStore } = useStores();
    const selectedTask = toDoStore.selectedTask;

    const handleAddSubtask = () => {
        toDoStore.createSubTask(selectedTask!.id, {
            id: '',
            title: '',
            description: '',
            completed: false,
            createdAt: new Date(),
            priority: TaskPriority.LOW,
            tag: Tag.STORY,
        });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={'Subtasks'}
            modalDimensions={{ width: 900, height: 600 }}
            includeFooter
        >
            <div>
                <h3>{selectedTask?.title || 'No Task Selected'}</h3>

                <ul>
                    {selectedTask?.subtasks?.map((subtask) => (
                        <li key={subtask.id}>{subtask.title}</li>
                    ))}
                </ul>

                <button
                    onClick={handleAddSubtask}
                    style={{
                        marginTop: '20px',
                        padding: '10px 15px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                >
                    Add New Subtask
                </button>

                <SubtaskForm onModalSubmitted={onSubmit} />
            </div>
        </Modal>
    );
};

export default SubtaskModal;
