import styled from 'styled-components';

// Color Palette
const colors = {
    primary: '#4B6F9E', // Modern blue
    secondary: '#F5A623', // Soft orange
    background: '#F8F9FA', // Light background
    border: '#E0E0E0', // Light gray for borders
    text: '#333333', // Dark text
    hover: '#E9E9E9', // Light hover effect
    link: '#007BFF', // Modern link color
};

// Typography
export const SchoolName = styled.h3`
    margin-bottom: 10px; // Increased spacing for better readability
    color: ${colors.text}; // Use modern text color
`;

// Buttons
export const StyledButton = styled.button`
    border: none;
    padding: 12px 16px; // Improved padding for better touch targets
    background-color: ${colors.primary}; // Modern button color
    color: white; // White text on button
    border-radius: 5px; // Rounded corners
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${colors.secondary}; // Change to secondary color on hover
    }
`;

export const AccordionControl = styled.button`
    cursor: pointer; // Indicate it's clickable
    width: 100%;
    text-align: left;
    padding: 12px; // Increased padding for better touch targets
    background-color: ${colors.background}; // Use background color
    border: none; // Remove border for a cleaner look
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${colors.hover}; // Change background color on hover
    }
`;

export const AccordionButton = styled.button`
    cursor: pointer; // Indicate it's clickable
    width: 100%;
    text-align: left;
    padding: 12px; // Increased padding for better touch targets
    background-color: ${colors.background}; // Use background color
    border: none;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${colors.hover}; // Change background color on hover
    }
`;

// Containers
export const AboutMeContainer = styled.div`
    margin: 20px 0; // Increased margin for better separation

    & > p:last-child {
        padding-bottom: 20px; // Ensure last paragraph has space below
    }

    @media (max-width: 768px) {
        margin: 15px 0; // Adjust margin for smaller screens
    }
`;

export const EducationContainer = styled.div`
    margin: 20px 0; // Increased margin for better separation

    @media (max-width: 768px) {
        margin: 15px 0; // Adjust margin for smaller screens
    }
`;

export const CoursesContainer = styled.div`
    color: ${colors.text}; // Use modern text color
    margin: 10px 0; // Added margin for spacing

    @media (max-width: 768px) {
        padding: 0 10px; // Add padding for mobile view
    }
`;

// Course Component
export const Course = styled.div`
    border: 2px solid ${colors.border}; // Modern border color
    margin-bottom: 30px; // Increased spacing between courses
    padding: 15px; // Increased padding for better readability

    @media (max-width: 768px) {
        margin-bottom: 20px; // Reduce margin on smaller screens
    }
`;

// Accordion Components
export const AccordionPanel = styled.div`
    display: none;
    overflow-y: auto; // Enable vertical scrolling
    max-height: 300px; // Set maximum height
    transition: max-height 0.2s ease-out;
    padding: 15px; // Increased padding for better readability
    box-sizing: border-box; // Ensure padding is included in max-height

    &.open {
        display: block; // Show when open
    }
`;

// Skills and Resources
export const SkillsAndResources = styled.div`
    margin: 20px 0; // Increased margin for better separation
    display: flex;
    flex-wrap: wrap;
    border: 2px solid ${colors.border}; // Modern border color
    padding: 15px; // Increased padding for better layout

    li {
        flex: 0 0 calc(33.33% - 20px); // Adjust width for 3 items per row
        padding: 10px; // Increased padding for better touch targets
        list-style-type: none;
        margin-bottom: 10px; // Add space between list items
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        li {
            flex: 0 0 100%; // Stack items vertically on smaller screens
        }
    }
`;

// Project Details
export const ProjectDetails = styled.div`
    padding: 20px 0; // Maintain vertical padding
    border-top: 1px solid ${colors.border}; // Use modern border color
`;

// Resources
export const ResourceList = styled.ul`
    padding: 0; // Remove default padding
`;

export const ResourceItem = styled.li`
    margin: 10px 0; // Increased margin for better spacing

    a {
        color: ${colors.link}; // Set modern link color
        text-decoration: none; // Remove underline from links

        &:hover {
            text-decoration: underline; // Underline on hover for better UX
        }
    }
`;

export const Container = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;

export const Nav = styled.nav`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`;

export const Button = styled.button<{ selected?: boolean }>`
    padding: 10px 15px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: ${({ selected }) => (selected ? '#0056b3' : '#007bff')}; // Active color
    color: ${({ selected }) => (selected ? 'white' : '#fff')};
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${({ selected }) =>
            selected ? '#004494' : '#0056b3'}; // Change hover color based on selection
    }
`;
