import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomePageSubHeaderContainer } from './HomePageSubHeader.style';
import { Button } from 'components/Pages/Home/Home.style';

const HomePageSubHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (section: string) => {
        navigate(`/home/${section}`);
    };

    const isActive = (section: string) => {
        return (
            location.pathname.includes(`/home/${section}`) || (location.pathname === '/home' && section === 'aboutme')
        );
    };

    return (
        <HomePageSubHeaderContainer>
            <Button onClick={() => handleNavigation('aboutme')} selected={isActive('aboutme')}>
                About Me
            </Button>
            <Button onClick={() => handleNavigation('workexperience')} selected={isActive('workexperience')}>
                Work Experience
            </Button>
            <Button onClick={() => handleNavigation('education')} selected={isActive('education')}>
                Education
            </Button>
        </HomePageSubHeaderContainer>
    );
};

export default HomePageSubHeader;
